<template>
  <div class="main">
    <!-- 活动记录 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        finished-text="没有更多了"
        @load="onLoad"
      >

        <div class="search_box_all">
          <!--轮播图-->
          <div class="circlediv">
            <div class="headimgdiv">
              <img
                :src="banner"
                alt=""
                class="headimg"
                @click.prevent="jumpOutJd"
              >
            </div>
          </div>
        </div>
        <div class="tab">
          <div
            v-for="(item) in tabList"
            :key="item.id"
            class="tab_item"
            :class="[item.id === goodsParams.classId ? 'tab_item_active' : '']"
            @click="changeTab(item.id)"
          >
            {{ item.text }}
          </div>
        </div>
        <div v-if="list.length > 0" class="list-content">
          <JdListRow
            v-if="list && goodsParams"
            :jd-list-row-data="list"
            :goods-params="goodsParams"
            :elite-type="eliteType"
          />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import Vue from 'vue'
import { Tab, Tabs, Toast, List, Cell, PullRefresh } from 'vant'
import JdListRow from '@/views/activity/component/jdList'
import { fetchJDChoiceGoodsList } from '@/services/shop-cps/shop-jd-cps'
import { wxConfigInit } from '@/utils/wx-api'
import { changeURLArg } from '@/utils/common/utils'

Vue.use(Tab).use(Tabs).use(Toast).use(List).use(Cell).use(PullRefresh)
export default {
  name: 'JdList',
  components: {
    JdListRow
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      error: false,
      page: 0,
      list: [],
      banner:
        'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-02/09/yuelvhuiGIy9QEwJCG1622598314.jpg',
      jdBannerList: {},
      goodsParams: {
        classId: 318,
        page: 1,
        pageSize: 10,
        loading: false,
        finished: false,
        error: false
      },
      tabList: [
        { id: 318, text: '2元礼金' },
        { id: 319, text: '5元礼金' }
      ],
      jdListSon: '', // jdlist
      shareData: {
        imgUrl:
          'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-04/10/yuelvhui0PVTIONmWQ1622774101.jpeg',
        title: '618京享礼金',
        desc: '补贴低价，好物抢不停'
      }
    }
  },
  computed: {
    eliteType() {
      return this.goodsParams.classId === 318 ? 1 : this.goodsParams.classId === 319 ? 2 : ''
    }
  },
  beforeMount() {},
  mounted() {
    this.handleSetShare()
  },
  methods: {
    /**
     * 设置分享
     * 2021-06-11 17:41:20
     * @author SnowRock
     */
    handleSetShare() {
      const shareInfo = {
        title: '618礼金补贴', // 分享标题
        desc: '悦淘福利，快来薅羊毛', // 分享描述
        link: changeURLArg(window.location.href, 'codeNumber', localStorage.getItem('recode')),
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-11/17/yuelvhuilv06cqqMCB1623405128.png' // 分享图标
      }
      wxConfigInit(shareInfo)
    },
    onLoad() {
      this.page++
      const params = {
        eliteId: this.goodsParams.classId,
        eliteType: this.eliteType, // eliteType   1=对应eliteId = 318 ， 2=对应eliteId   319
        page: this.page
      }
      fetchJDChoiceGoodsList(params).then(res => {
        const { code, data } = res
        this.loading = false
        if (code === 200) {
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }

          this.list = this.list.concat(data)
          this.finished = data < 10
        } else {
          this.error = true
          this.page--
        }
      }).catch(() => {
        this.loading = false
        this.error = true
        this.page--
      })
    },
    /**
     * 下拉刷新
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.list = []
      this.page = 0

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    jumpOutJd() {
      location.href = this.jdBannerList.bannerData[0].url
    },

    changeTab(id) {
      this.goodsParams.classId = id
      this.goodsParams.page = 1
      this.list = []
      this.onRefresh()
    }
  }
}
</script>
<style lang="less" scoped>
.main {
}
.search_box_top {
  background: #fff;
  padding-top: 12px;
  box-sizing: border-box;
}
.search_box_img {
  position: absolute;
  top: 18px;
  left: 16px;
  width: 20px;
  height: 20px;
}
.search_box_input {
  width: 84%;
  height: 30px;
  line-height: 34px;
  font-size: 12px;
  background: #fff;
}
.search_box_all {
  background: #fff;
  //margin-top: 12px;
}
.search_box_img {
  position: absolute;
  top: 6px;
  left: 8px;
  width: 20px;
  height: 20px;
}
.circlediv {
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: #fff;
  height: 216px;
  position: relative;
  margin-top: -5px;
}
.circle {
  width: 140%;
  height: 50px;
  background: #e03022;
  border-radius: 0 0 50% 50%;
  border-top: none;
  position: relative;
  left: -20%;
}
.headimgdiv {
  position: absolute;
  width: 375px;
  height: 216px;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headimg {
  width: 375px;
  height: 216px;
}

.goodsList {
  margin-top: 10px;
  width: 375px;
}
.tab {
  width: 90%;
  height: 23px;
  margin: 16px auto 13px;
  display: flex;
  justify-content: space-around;
}
.tab_item {
  width: 60px;
  height: 15px;
  font-weight: 500;
  color: #000000;
  font-size: 14px;
  position: relative;
}
.tab_item_active:before {
  content: "";
  width: 20px;
  height: 6px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: url("../../../assets/images/tabIndex.png") no-repeat;
  background-size: 100% 100%;
  bottom: -10px;
}
</style>
