<template>
  <div>
    <!--new list-->
    <div class="new_BoxCell_all">
      <div v-if="jdListRowData" class="new_BoxCell">
        <div
          v-for="(item, index) in jdListRowData"
          :key="index"
          class="new_BoxCell_list"
          @click.prevent="jumpJdProduct(item.goodsInfo)"
        >
          <imgLoading
            :src="item.goodsInfo.goods_image"
            class="new_BoxCell_list_img"
          />
          <div class="new_BoxCell_son">
            <div class="type_title">
              <span>{{ item.goodsInfo.goods_name }}</span>
            </div>
            <div class="type_share">
              <div v-if="item.giftCoupon" class="gift left">
                <div class="title">礼金</div>
                <div class="gift_price">￥{{ item.giftCoupon }}</div>
              </div>
              <div v-if="item.coupon.discount" class="type_share_img left">
                {{ item.coupon.discount }}元券
              </div>
              <div class="both" />
            </div>
            <div class="type_price_top">
              <span class="price">
                <em>¥</em>
                {{ item.goodsInfo.jd_price }}
              </span>
              <span class="price_discount">
                <em>¥</em>
                {{ item.goodsInfo.original_price }}
              </span>
              <span class="price_to">{{ item.goodsInfo.sale_num }}人已购</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgLoading from '@/component/imgLoading'
export default {
  name: 'JdListRow',
  components: {
    imgLoading
  },
  props: {
    jdListRowData: {
      type: null,
      default: null
    },
    eliteType: {
      type: null,
      default: null
    },
    goodsParams: {
      type: null,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    jump_buy(url) {
      location.href = url
    },
    // 跳转京东商品详情
    jumpJdProduct(item) {
      // /goodsDetailCPS?goodsId='商品ID'&status=（3:京东；4:拼多多；5:淘宝；6:唯品会）

      const obj = {
        goodId: item.goods_id,
        skuId: '',
        eliteType: this.eliteType,
        product_type: 3
      }

      console.log(obj)
      this.$store.dispatch('godetail', obj)

      // this.$router.push({
      //     path: '/goodsDetailCPS',
      //     query: {
      //         goodsId: goodsId,
      //         status: 3
      //     }
      // })liveId
    }
  }
}
</script>
<style lang="less" scoped>
.new_BoxCell_all {
  width: 100%;
  background: #f6f6f6;
}
.new_BoxCell {
  margin-left: 12px;
  margin-right: 12px;
  overflow: hidden;
  padding-top: 12px;
  box-sizing: border-box;
}
.new_BoxCell_list {
  width: 48%;
  background: #fff;
  border-radius: 8px;
  float: left;
  padding-bottom: 4px;
  box-sizing: border-box;
  margin-right: 12px;
  margin-bottom: 12px;
  overflow: hidden;
}
.new_BoxCell_list:nth-child(2n) {
  margin-right: 0px;
}
.type_name {
  background: #dd2727;
  color: #fff;
  font-size: 12px;
}
.type_title {
  color: #141414;
  font-size: 0.4rem;
  height: 1.01333rem;
  line-height: 0.53333rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.new_BoxCell_list_img {
  border-radius: 6px 6px 0 0;
}
.type_share {
  margin-top: 12px;
}
.type_share_img {
  background: url("../../../assets/images/yhj_red.png") no-repeat;
  background-size: 56px 19px;
  width: 56px;
  height: 19px;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  color: #fff;
}
.type_share_price {
  background: rgba(230, 153, 153, 0.5);
  border-radius: 4px;
  color: #ed2e2e;
  padding: 2px 4px;
  font-size: 10px;
  box-sizing: border-box;
  line-height: 14px;
}
.new_BoxCell_son {
  padding: 5px;
  box-sizing: border-box;
}

.left {
  float: left;
}
.both {
  clear: both;
}
.type_price_top {
  margin-top: 12px;
  text-align: left;
}
.price {
  color: #ed2e2e;
  font-size: 13px;
  font-weight: 500;
  em {
    font-size: 10px;
  }
}
.price_discount {
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-left: 1px;
  em {
    font-size: 10px;
  }
}
.price_to {
  font-size: 10px;
  color: #999999;
  margin-left: 4px;
  float: right;
  margin-top: 3px;
}
.middle_guide_all {
  margin: 12px;
  overflow: hidden;
  background: #fff;
}
.middle_guide_box {
  float: left;
  width: 20%;
}
.middle_guide_img {
  width: 40px;
  height: 40px;
  margin: 6px auto;
  border-radius: 50%;
}
.middle_guide_name {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.gift {
  width: 73px;
  height: 19px;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  margin-right: 7px;
  font-family: PingFang SC, PingFang SC-Regular;
  .title {
    width: 28px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #323232;
    color: #ffffff;
  }
  .gift_price{
    width: 45px;
    background-color: #FECC88;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
